import React from 'react';
import Card from './Card';


// const CardList = ({ robots }) => {
//     const cardComponent = robots.map((user, i) => {
//         return (
//             <Card
//                 key={i}
//                 id={robots[i].id}
//                 name={robots[i].name}
//                 email={robots[i].email}
//             />
//         );
//     })
//     return (
//         <div>
//             {cardComponent}
//         </div>
//     );
// }

// Clean up even further!
const CardList = ({ robots }) => {
    return (
        <div>
            {
                robots.map((user, i) => {
                    return (
                        <Card
                            key={i}
                            id={robots[i].id}
                            name={robots[i].name}
                            email={robots[i].email}
                        />
                    );
                })
            }
        </div>
    );
}
export default CardList;